import { detect } from "detect-browser";
import { getUrlParamValue, generateSmileboxShortcuts } from "./utils";

const browser = detect();
const isChrome = browser.name === "chrome";

const EDGE_SBID = "91139";
const CHROME_SBID = "91428";
const LS_GD_NAME = "smilebox_gd";
const valueFromLS = localStorage.getItem(LS_GD_NAME);
const defaultGD = isChrome ? "RD1002755" : "RD1002540";

const appStateEdge = {
  browserName: "Edge",
  publisherId: localStorage.getItem(LS_GD_NAME) || defaultGD,
  smileboxShortcuts: generateSmileboxShortcuts(EDGE_SBID),
  extensionInstallLink:
    "https://microsoftedge.microsoft.com/addons/detail/smilebox-tab/nifpcmccbingjhodmmjebgcdfidkpggg",
};

const appStateChrome = {
  browserName: "Chrome",
  publisherId: localStorage.getItem(LS_GD_NAME) || defaultGD,
  smileboxShortcuts: generateSmileboxShortcuts(CHROME_SBID),
  extensionInstallLink:
    "https://chrome.google.com/webstore/detail/smilebox-tab/ekfgfhknjepopojlaicagndpgkcdahmc?hl=en&authuser=0",
};

const checkForGDInQueryAndSetToLS = () => {
  const gdFromUrl = getUrlParamValue("gd");
  const LS_GD_VALUE = gdFromUrl || defaultGD;

  if (gdFromUrl && !valueFromLS) {
    localStorage.setItem(LS_GD_NAME, LS_GD_VALUE);
  }
};

const setupAppState = () => {
  checkForGDInQueryAndSetToLS();

  const appState = isChrome ? appStateChrome : appStateEdge;

  return appState;
};

export default setupAppState;
