import { Link } from "react-router-dom";

import logoSrc from "../../images/logo.png";

import "./LegalWrapper.scss";

const LegalWrapper = (props) => {
  return (
    <div className="legal">
      <header>
        <Link to="/">
          <img className="header-logo" alt="logo" src={logoSrc} />
        </Link>
      </header>

      {props.children}
    </div>
  );
};

export default LegalWrapper;
