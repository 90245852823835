import React from "react";

const SmileboxShortcut = ({ shortcut }) => {
  return (
    <div className="shortcut-container">
      {/* eslint-disable-next-line */}
      <a
        href={shortcut?.url}
        className={`shortcut smilebox-shortcut-${shortcut.className}`}
      ></a>

      <span className="shortcut-title">{shortcut?.title}</span>
    </div>
  );
};

export default SmileboxShortcut;
