import {useEffect} from "react";

import {sendMixpanelEvent} from "../../utils";

import PageTitle from "../Common/PageTitle";

import './PrivacyPolicy.scss';

const PrivacyPolicy = () => {
    useEffect(() => {
        sendMixpanelEvent("Privacy Policy");
    }, []);

    let companyName = 'Smilebox Inc.';
    let shortCompanyName = 'Smilebox';
    let privacyPolicyEmail = 'smileboxapps@smilebox.com ';
    let dsrLink = "https://www.codefuel.com/wp-content/uploads/2022/12/CodeFuel-DSR.pdf";
    let repEmail = 'rep_19128250480@prighter.com';

    return (
        <div className="content">
            <PageTitle title="Privacy Policy"/>

            <div className="content-wrapper">
                <div className="mainTitle">
                    <h1>PRIVACY POLICY</h1>
                    <div className="text-italic lastModified">[Last Modified: December 21, 2023]</div>
                </div>

                <p>
                    This privacy policy ("<span className="text-bold">Privacy Policy</span>" or "<span
                    className="text-bold">Policy</span>") describes how {companyName} D/B/A {shortCompanyName},
                    ("<span className="text-bold">{shortCompanyName}</span>", "<span className="text-bold">we</span>",
                    "<span className="text-bold">us</span>", or "<span className="text-bold">our</span>"),
                    collect and process certain information received when we provide our services, through our web-apps,
                    mobile apps, desktop-apps, web-pages, platform, software, extensions and other properties ("<span
                    className="text-bold">Properties</span>" and collectively with the services shall be referred herein
                    as "<span className="text-bold">Services</span>") to our direct or indirect users, customers, all as
                    detailed below.
                </p>

                <ol className="sections">
                    <li className="section">
                        <div className="section-header" id="section-1">INTRODUCTION:</div>
                        <div className="section-content">
                            <p>
                                This Privacy Policy describes {shortCompanyName} information collection and use
                                while <span className="text-bold">(i)</span> direct users install and
                                use our apps (web-apps, desktop apps and mobile apps, as applicable), or when they
                                browse and view our
                                websites, search portals, or otherwise any content published or made available by us;
                                or <span className="text-bold">(ii)</span> when
                                indirect users interact with the Service through a Partners' app, software or web-page.
                            </p>
                            <p>
                                A "<span className="text-bold text-italic">Partner</span>" is a business that embeds our
                                feed, content or ads within their app, in which we collect
                                certain information on the partner and also on the users interacting with the Services
                                through such
                                Partner (i.e., indirect customer).
                            </p>
                            <p>
                                We understand how important it is to keep such users' Personal Data (as defined below,
                                we have designed this
                                Privacy Policy in a format that is easy to navigate and read so that our privacy
                                practices can be clearly
                                understood.
                            </p>
                            <p>
                                Note you are not required by law to provide us with any Personal Data. Sharing Personal
                                Data with us is entirely voluntary.
                            </p>
                            <p>
                                This Privacy Policy governs the use, processing and sharing of Personal Data that
                                applies to all individuals world-wide, however, certain jurisdictions require that
                                applicable disclosures will be provided in a certain way and format, and therefore
                                additional notices will apply as follows:
                            </p>
                            <p>
                                <span className="text-bold text-underline">Additional Information to California Residents:</span> In
                                the event you are a California resident– please also review our <a
                                href="https://www.codefuel.com/ccpanotice/" target="_blank">CCPA Privacy Notice</a> to
                                learn more about our privacy practices with respect to the California Consumer Privacy
                                Act.
                            </p>
                            <p>
                                <span
                                    className="text-bold text-underline">Additional Information to Colorado Residents:</span> In
                                the event you are a Colorado resident – please also review our <a href="#colorado">CPA
                                Notice</a> to learn more about our privacy practices and your rights under the Colorado
                                Privacy Act.
                            </p>
                            <p>
                                <span className="text-bold text-underline">Additional Information to Connecticut Residents:</span> In
                                the event you are a Connecticut resident– please also review our <a href="#connecticut">CDPA
                                Notice</a> to learn more about your rights under the Connecticut Data Privacy Act.
                            </p>
                            <p>
                                <span
                                    className="text-bold text-underline">Additional Information to Utah Residents:</span> In
                                the event you are a Utah resident – please also review our <a href="#utah">UCPA
                                Notice</a> to learn more about your rights under the Utah Consumer Privacy Act.
                            </p>
                            <p>
                                <span
                                    className="text-bold text-underline">Additional Information to Virginia Residents:</span> In
                                the event you are a Virginia resident– please also review our <a href="#virginia">VCDPA
                                Notice</a> to learn more about our privacy practices and your rights under the Virginia
                                Consumer Data Protection Act.
                            </p>
                        </div>
                    </li>

                    <li className="section">
                        <div className="section-header" id="section-2">POLICY AMENDMENTS:</div>
                        <div className="section-content">
                            We evaluate this Privacy Policy and procedures to implement improvements and refinements
                            from time to time. We will review and update this Privacy Policy as necessary at least every
                            12 months. The most recent version of this Privacy Policy will always be posted on the
                            website. The updated date of the Policy will be reflected in the "Last Modified" heading. We
                            will provide notice if these changes are material and, where required by applicable law; we
                            will obtain your consent. Any amendments to the Privacy Policy will become effective
                            immediately, unless we notify otherwise. We recommend you review this Privacy Policy
                            periodically to ensure that you understand our most updated privacy practices.
                        </div>
                    </li>

                    <li className="section">
                        <div className="section-header" id="section-3">CONTACT INFORMATION AND DATA CONTROLLER
                            INFORMATION:
                        </div>
                        <div className="section-content">
                            <p>{companyName} D/B/A {shortCompanyName}, incorporated under the laws of the
                                State of Israel, is the controller of the Personal Data listed below, unless otherwise
                                stated.</p>
                            <p>For any question, inquiry or concern related to this Privacy Policy or the processing of
                                your
                                Personal Data, you may contact our privacy team as follows:</p>
                            <p>
                                <span className="text-bold">Data Protection Officer: </span>
                                <a href={`mailto:${privacyPolicyEmail}`}>{privacyPolicyEmail}</a>.
                            </p>
                            <p>
                                {companyName}<br/>
                                One World Trade Center, <br/>
                                71st floor, New York 10007, USA
                            </p>
                            <p className="text-bold">Data Protection Representative for Data Subjects in the EU, UK and
                                Swiss:</p>
                            <p>We value your privacy and your rights as a data subject and have therefore appointed
                                <span className="text-bold"> Prighter Group </span>
                                with its local partners as our privacy representative and your point of contact.</p>
                            <p>Prighter gives you an easy way to exercise your privacy-related rights (e.g., requests to
                                access or
                                erase personal data). If you want to contact us via our representative, Prighter or make
                                use
                                of your
                                data subject rights, please visit the following website.
                                <a href="https://prighter.com/q/11106546394"
                                   target="_blank"> https://prighter.com/q/11106546394</a>.</p>
                            <p><span className="text-bold">Attn:</span> Andreas M&#228;tzler</p>
                            <p><span className="text-bold">Email: </span>
                                <a href={`mailto:${repEmail}`}>{repEmail}</a>.</p>
                        </div>
                    </li>

                    <li className="section">
                        <div className="section-header" id="section-4">DATA SETS WE COLLECT AND FOR WHAT PURPOSE:</div>
                        <div className="section-content">
                            <p>We may collect two types of information from you, depending on your interaction with
                                us.</p>
                            <p>The first type of information is non-identifiable and anonymous information ("<span
                                className="text-bold">Non-Personal Data</span>"). We are not aware of the identity of
                                the individual
                                from who we have collected the Non-Personal Data. Non-Personal Data which is being
                                gathered consists
                                of technical information, and may contain, among other things, the type of operating
                                system and type
                                of browser, type of device, your action in the website or Services (such as session
                                duration).</p>
                            <p>The second type of information is individually identifiable information, namely
                                information that
                                identifies an individual or may with reasonable effort identify an individual (<span
                                    className="text-bold"></span>"<span className="text-bold">Personal
                Data</span>" or "<span className="text-bold">Personal Information</span>" as term may be applicable each
                                data protection law).</p>
                            <p>For the avoidance of doubt, any Non-Personal Data connected or linked to Personal Data
                                shall be
                                deemed as Personal Data as long as such connection or linkage exists.</p>
                            <p>The table below details the types of Personal Data we process, the purpose, lawful basis,
                                and our
                                processing operations:</p>

                            <table>
                                <thead>
                                <tr>
                                    <th>DATA SET</th>
                                    <th>PURPOSE AND OPERATIONS</th>
                                    <th>LAWFUL BASIS</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <div className="text-bold text-underline">Online Identifiers:</div>
                                        When you access and interact with our Services, we collect certain online
                                        identifiers such as IP
                                        address, device ID, device type and carrier name, advertising ID or similar
                                        unique online
                                        identifiers ("<span className="text-bold">Online
                                Identifiers</span>"). Once or during the installation we will also know of other
                                        software you might have on the device.
                                    </td>
                                    <td>We process your Online Identifiers to extract country level location, for
                                        reporting purposes
                                        (i.e., identify complete installs of our apps or our Partners' apps), to know
                                        when and how the
                                        Services were accessed, analytic purposes and marketing purposes if we combine
                                        the identifier
                                        with additional information. In addition, we process such data for operation,
                                        security and fraud
                                        prevention purposes.
                                    </td>
                                    <td>Where we collect your Online Identifiers for analytic, security and performance
                                        purposes, we
                                        process the data based on our legitimate interest in providing the Services or
                                        researching on
                                        how to improve it. We balance those needs with the users right to establish the
                                        legitimate
                                        interest claim and provide applicable disclosures.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="text-bold text-underline">Contact Information:</div>
                                        If you voluntarily contact us for support or other inquiries, you may be
                                        required to provide us
                                        with certain information such as your name, email address, organization name,
                                        etc. ("<span
                                        className="text-bold">Contact
                                    Information</span>").
                                    </td>
                                    <td><p>We collect your Contact Information to provide you with the support you
                                        requested or
                                        to respond to your inquiry.
                                    </p>
                                        <p>The correspondence with you may be processed and stored by us in order to
                                            improve our
                                            customer service and in the event, we believe it is required to continue to
                                            store it,
                                            for example, in the event of any claims or in order to provide you with any
                                            further
                                            assistance (if applicable).
                                        </p></td>
                                    <td><p>We process your Contact Information subject to our legitimate interest.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="text-bold text-underline">Usage Data:</div>
                                        We collect certain information regarding your use and interaction with our
                                        Services, including
                                        button clicks, search queries, time and date of each search query, search terms
                                        and browsing
                                        history. In addition, when you view ads served by us or have other interaction
                                        with ads served
                                        by us, we may collect information about your interaction with such ads, for
                                        example, information
                                        that you have viewed or clicked an ad ("<span
                                        className="text-bold">Usage
                                    Data</span>").
                                    </td>
                                    <td><p>We use your Usage Data to understand how our Services are used, so that we
                                        can
                                        improve it.</p>
                                        <p>We also use your Usage Data and to enhance the Service, make sure you receive
                                            accurate
                                            result.</p>
                                        <p>We also use the Usage Data to engage you, make sure you enjoy the features
                                            and provide you
                                            with additional features.</p>
                                    </td>
                                    <td>based on our legitimate interest in providing the Services or researching on how
                                        to improve
                                        it.
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <p>Please note that the actual processing operation per each purpose of use and lawful basis
                                detailed in the
                                table above, may differ. Such processing operation usually includes set of operations,
                                made by automated
                                means,
                                such as collection, storage, use, disclosure by transmission, erasure or destruction.
                                The transfer of
                                Personal
                                Data to third party countries as further detailed in <a href="#section-10">Section
                                    10</a> “INTERNATIONAL
                                DATA TRANSFER” below, is based on
                                the same lawful basis as stipulated in the table above.</p>
                            <p>In addition, we may use certain Personal Data to prevent potentially prohibited or
                                illegal activities,
                                fraud,
                                misappropriation, infringements, identity thefts and any other misuse of the Services
                                and to enforce our
                                policies and agreements, as well as to protect the security or integrity of our
                                databases and the
                                Services,
                                and to take precautions against legal liability. Such processing is based on our
                                legitimate
                                interests.</p>
                        </div>
                    </li>

                    <li className="section">
                        <div className="section-header" id="section-5">HOW WE COLLECT YOUR INFORMATION:</div>
                        <div className="section-content">
                            Depending on the nature of your interaction with the Services, or any service provided by us
                            or our
                            Partners, we may collect information either
                            <span className="text-bold">automatically</span> or <span
                            className="text-bold">voluntarily</span> provided
                            by you. Automatic collection of information is usually made through the use of cookies,
                            pixel tags, local
                            storage, databases, and server logs, and voluntarily information is provided when you
                            contact us, type in a
                            search term, etc.
                        </div>
                    </li>

                    <li className="section">
                        <div className="section-header" id="section-6">COOKIES AND SIMILAR TRACKING TECHNOLOGIES:</div>
                        <div className="section-content">
                            <p>When you interact and use our Services, we, or our Partners, place "cookies" and similar
                                tracking
                                technologies such as Software Developer Kits ('SDKs'), pixels or web beacons, that
                                collect certain
                                information about your use and interaction with the Properties and Services and use this
                                information
                                for the operation and functionality of the Services as well as for analytics and
                                marketing purposes,
                                all as detailed in the table above. You can find more information about cookies at
                                <a href="http://www.allaboutcookies.org/" target="_blank">www.allaboutcookies.org</a>.
                                Currently, we
                                use the following Cookies and SDKs:</p>

                            <table id="cookies">
                                <thead>
                                <tr>
                                    <th>Cookie</th>
                                    <th>Purpose</th>
                                    <th>Privacy Policy</th>
                                    <th>Opt-out</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Google Analytic</td>
                                    <td>Analytical and Performance</td>
                                    <td><a href="http://www.google.com/policies/privacy/partners"
                                           target="_blank">www.google.com/policies/privacy/partners</a></td>
                                    <td><p><a href="https://policies.google.com/technologies/managing?hl=en"
                                              target="_blank">https://policies.google.com/technologies/managing?hl=en</a>
                                    </p>
                                        <p><a href="https://tools.google.com/dlpage/gaoptout/"
                                              target="_blank">https://tools.google.com/dlpage/gaoptout/</a></p>
                                        <p>For additional information regarding our use of Google products, click <a
                                            href="https://policies.google.com/technologies/partner-sites"
                                            target="_blank">here</a>.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Bing</td>
                                    <td>Provide the Search results.</td>
                                    <td>Microsoft <span className="text-bold">privacy policy</span> is available <a
                                        href="https://privacy.microsoft.com/en-us/privacystatement"
                                        target="_blank">HERE</a></td>
                                    <td><a
                                        href="https://about.ads.microsoft.com/en-us/resources/policies/personalized-ads"
                                        target="_blank">https://about.ads.microsoft.com/en-us/resources/policies/personalized-ads</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Yahoo</td>
                                    <td>Provide the SERP</td>
                                    <td>Oath <span className="text-bold">privacy policy</span> is available <a
                                        href="https://policies.oath.com/us/en/oath/privacy/products/searchservices/index.html"
                                        target="_blank">HERE</a></td>
                                    <td><a href="https://legal.yahoo.com/xw/en/yahoo/privacy/optout/index.html"
                                           target="_blank">https://legal.yahoo.com/xw/en/yahoo/privacy/optout/index.html</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Microsoft Clarity</td>
                                    <td>Analytical and Performance</td>
                                    <td>Microsoft <span className="text-bold">privacy policy</span> is available <a
                                        href="https://privacy.microsoft.com/en-us/privacystatement"
                                        target="_blank">HERE</a></td>
                                    <td><a
                                        href="https://about.ads.microsoft.com/en-us/resources/policies/personalized-ads"
                                        target="_blank">https://about.ads.microsoft.com/en-us/resources/policies/personalized-ads</a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </li>

                    <li className="section">
                        <div className="section-header" id="section-7">DATA SHARING - CATEGORIES OF RECIPIENTS WE SHARE
                            PERSONAL DATA
                            WITH:
                        </div>
                        <div className="section-content">
                            <p>We share your Personal Data with third parties, including with business partners or
                                service providers
                                that help us provide our Services. You can find here information about the categories of
                                such
                                third-party recipients.</p>

                            <table>
                                <thead>
                                <tr>
                                    <th>CATEGORY OF RECIPIENT</th>
                                    <th>DATA THAT WILL BE SHARED</th>
                                    <th>PURPOSE OF SHARING</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td><p>Search feed provider ("<span className="text-bold">Search Partner</span>").
                                    </p>
                                        Our current Search Partners are Microsoft and Oath.
                                    </td>
                                    <td>Online Identifiers, and Usage Data.</td>
                                    <td><p>We share the search terms so that the search partner can display through its
                                        search
                                        page.</p>
                                        <p><span className="text-bold">Microsoft (Bing):</span> Microsoft <span
                                            className="text-bold">privacy policy</span> is
                                            available
                                            <a href="https://privacy.microsoft.com/en-us/privacystatement"
                                               target="_blank">HERE</a>.</p>
                                        <p><span className="text-bold">Oath (Yahoo):</span> Oath <span
                                            className="text-bold">privacy policy</span> is
                                            available
                                            <a href="https://policies.oath.com/us/en/oath/privacy/products/searchservices/index.html"
                                               target="_blank">HERE</a>.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Media Buyers</td>
                                    <td>Online Identifiers</td>
                                    <td>When we buy media online, we may attach to the campaign a unique identifier. The
                                        unique
                                        identifier provided to the media owner, may become personally identifiable when
                                        such media owner
                                        crosses our parameters with its own existed information about an individual.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Service Providers</td>
                                    <td>All types of Personal Data</td>
                                    <td>We may disclose Personal Data to our trusted agents and service providers
                                        (including, but not
                                        limited to, our Cloud service provider, our analytics service provider, our CRM
                                        provider, etc.)
                                        so that they can perform requested services on our behalf. Thus, we share your
                                        data with third
                                        party entities, for the purpose of storing such information on our behalf, or
                                        for other
                                        processing needs. These entities are prohibited from using your Personal Data
                                        for any purposes
                                        other than providing us with requested services.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Any acquirer of our business</td>
                                    <td>All types of Personal Data</td>
                                    <td>We may share Personal Data, in the event of a corporate transaction (e.g., sale
                                        of a substantial
                                        part of our business, merger, consolidation or asset sale). In the event of the
                                        above, our
                                        affiliated companies or acquiring company will assume the rights and obligations
                                        as described in
                                        this Policy.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Affiliated companies</td>
                                    <td>All types of Personal Data</td>
                                    <td>We may share certain information with our affiliated companies, which will
                                        provide us with
                                        certain required services and, for internal compliance and measurement, etc.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Governmental agencies, or authorized third parties</td>
                                    <td>Subject to law enforcement authority request.</td>
                                    <td>
                                        <p>We may share certain data when we believe it is appropriate to do so in order
                                            to comply with
                                            the law enforcement, governmental agencies or authorized third parties, or
                                            protect the
                                            rights, property, or security of {shortCompanyName} , our customers,
                                            partners, or
                                            others.</p>
                                        <p>We may disclose Personal Data to enforce our policies and agreements, as well
                                            as defend our
                                            rights, including the investigation of potential violations thereof, alleged
                                            illegal
                                            activity or any other activity that may expose us, you, or other users to
                                            legal liability,
                                            and solely to the extent required. In addition, we may disclose Personal
                                            Data to detect,
                                            prevent, or otherwise address fraud, security, or technical issues, solely
                                            to the extent
                                            required.</p>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <p>Where we share information with services provider and agents, we ensure they only have
                                access to such
                                information that is strictly necessary in order for us to provide the Services. These
                                parties are
                                required to secure the data they receive and to use the data for pre-agreed purposes
                                only, while
                                ensuring compliance with all applicable data protection regulations (such service
                                providers may use
                                other non-personal data for their own benefit).</p>
                        </div>
                    </li>

                    <li className="section">
                        <div className="section-header" id="section-8">DATA RETENTION:</div>
                        <div className="section-content">
                            <p>In general, we retain the Personal Data we collect for as long as it remains necessary
                                for the
                                purposes set forth above, all under the applicable regulation, or until you will express
                                your
                                preference to opt-out, where applicable.</p>
                            <p>The retention periods are determined according to the following criteria:</p>

                            <ol className="roman">
                                <li>For as long as it remains necessary in order to achieve the purpose for which the
                                    Personal Data was
                                    initially processed.
                                </li>
                                <li>Where we are required to retain Personal Data in accordance with legal, regulatory,
                                    tax, or
                                    accounting requirement.
                                </li>
                                <li>Where we deem retention is necessary to obtain an accurate record of your dealings
                                    with us in the
                                    event of any complaints or challenges.
                                </li>
                                <li>If we reasonably believe there is a prospect of litigation relating to your Personal
                                    Information.
                                </li>
                            </ol>

                            Other circumstances in which we will retain your Personal Data for longer periods of time
                            include: (i) where
                            we are required to do so in accordance with legal, regulatory, tax or accounting
                            requirements, or (ii) for
                            us to have an accurate record of your dealings with us in the event of any complaints or
                            challenges, or
                            (iii) if we reasonably believe there is a prospect of litigation relating to your Personal
                            Data. Please note
                            that except as required by applicable law, we will not be obligated to retain your data for
                            any particular
                            period, and we may delete it for any reason and at any time, without providing you with
                            prior notice if our
                            intention to do so.
                        </div>
                    </li>

                    <li className="section">
                        <div className="section-header" id="section-9">SECURITY MEASURES:</div>
                        <div className="section-content">
                            <p>We work hard to protect Personal Data we process from unauthorized access or unauthorized
                                alteration,
                                disclosure or destruction. We have implemented physical, technical and administrative
                                security
                                measures for the Services that comply with applicable laws and industry such as:
                                encryption using
                                SSL, we minimize amount of data that we store on our servers, restrict access to
                                Personal Data to {shortCompanyName} employees, contractors and agents, etc. Note that, we cannot be held
                                responsible for
                                unauthorized or unintended access that is beyond our control, and we make no warranty,
                                express,
                                implied or otherwise, that we will always be able to prevent such access.</p>
                            <p>Please contact us at: <a href={`mailto:${privacyPolicyEmail}`}>{privacyPolicyEmail}
                            </a> if you feel that
                                your
                                privacy was not dealt with properly, in a way that was in breach of our Privacy Policy
                                or if
                                you become
                                aware of a third party's attempt to gain unauthorized access to any of your Personal
                                Data.
                                We will make
                                a reasonable effort to notify you and the appropriate authorities (if required by
                                applicable
                                law) in the
                                event that we discover a security incident related to your Personal Data.
                            </p>
                        </div>
                    </li>

                    <li className="section">
                        <div className="section-header" id="section-10">INTERNATIONAL DATA TRANSFER:</div>
                        <div className="section-content">
                            <p>Our data servers in which we host and store the information are located in the US. The
                                Company’s HQ
                                are based in Israel, and therefore your Personal Data may be accessed from territories
                                which are not
                                your country of residence. In the event that we need to transfer your Personal Data out
                                of your
                                jurisdiction, we will take appropriate measures to ensure that your Personal Data
                                receive an adequate
                                level of protection as required under applicable law. Furthermore, when Personal Data
                                that are
                                collected within the European Economic Area ("<span className="text-bold">EEA</span>"),
                                the UK or Swiss
                                are transferred outside of the
                                EEA, the UK or Swiss (respectively) to a country that has not received an adequacy
                                decision from the
                                European Commission or an equivalent competent authorities, we will take necessary steps
                                in order to
                                ensure that sufficient safeguards are provided during the transferring of such Personal
                                Data,
                                in accordance with the provision of the <a
                                    href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en"
                                    target="_blank">standard contractual clauses</a> approved by the European Union
                                ("<span className="text-bold">SCCs</span>") or the <a
                                    href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/international-data-transfer-agreement-and-guidance/"
                                    target="_blank">UK standard contractual clauses</a> ("<span className="text-bold">UK SCCs</span>")
                                as approved by the UK Information Commissioner
                                Office ("<span className="text-bold">ICO</span>"), as applicable.</p>
                        </div>
                    </li>

                    <li className="section">
                        <div className="section-header" id="section-11">ELIGIBILITY AND CHILDREN PRIVACY</div>
                        <div className="section-content">
                            The Services and Properties are not intended for use by children under the age of 16 and we
                            do not knowingly
                            process children’s information. We will discard any information that we receive from a user
                            that is
                            considered a "child" immediately upon our discovery that such a user shared information with
                            us. Please
                            contact us at: <a href={`mailto:${privacyPolicyEmail}`}>{privacyPolicyEmail}</a>
                            if you have reason to believe that a child has shared any information with us.
                        </div>
                    </li>

                    <li className="section">
                        <div className="section-header" id="section-12">USER RIGHTS</div>
                        <div className="section-content">
                            <p>We acknowledge that different people have different privacy concerns and preferences. Our
                                goal is to
                                be clear about what information we collect so that you can make meaningful choices about
                                how it is
                                used. We allow you to exercise certain choices, rights, and controls in connection with
                                your
                                information. Depending on your relationship with us, your jurisdiction and the
                                applicable data
                                protection laws that apply to you, you have the right to control and request certain
                                limitations or
                                rights to be executed.</p>
                            <p>In the table below you can review your rights, how you can exercise them, and appeal a
                                decision we take
                                in this regard, any specification per geo-location or territory are available below the
                                table:</p>

                            <table id="rights">
                                <tbody>
                                <tr>
                                    <td>RIGHT TO BE INFORMED, RIGHT TO KNOW</td>
                                    <td>You have the right to confirm whether we collect Personal Data or Personal
                                        Information about
                                        you, if you wish to know if we collect Personal Data about you, please review
                                        this Privacy
                                        Policy.
                                    </td>
                                </tr>
                                <tr>
                                    <td>ACCESS RIGHTS</td>
                                    <td>You further have the right to know which Personal Data we specifically hold
                                        about you, and
                                        receive a copy of such or access it, if you wish to receive a copy of the
                                        Personal Data, please
                                        submit a DSR as available <a href={dsrLink} target="_blank">here</a>.
                                    </td>
                                </tr>
                                <tr>
                                    <td>RIGHT TO CORRECTION</td>
                                    <td>You have the right to correct inaccuracies in your Personal Information, or
                                        Personal Data,
                                        taking into account the nature of the processing and the purposes. Please submit
                                        a DSR as
                                        available <a href={dsrLink} target="_blank">here</a>.
                                    </td>
                                </tr>
                                <tr>
                                    <td>RIGHT TO BE FORGOTTEN, RIGHT TO DELETION</td>
                                    <td>In certain circumstances, you have the right to delete the Personal Data or
                                        Personal Information
                                        we hold about you.
                                        You do not need to create an account with us to submit a request to know or
                                        delete. Please
                                        submit a DSR as available <a
                                            href={dsrLink}
                                            target="_blank">here</a>.
                                    </td>
                                </tr>
                                <tr>
                                    <td>RIGHT TO PORTABILITY</td>
                                    <td>You have the right to obtain the personal data Personal Data or Personal
                                        Information in a
                                        portable, and to the extent technically feasible, readily usable format that
                                        allows you to
                                        transmit the data to another entity without hindrance. We will select the format
                                        in which we
                                        provide your copy. If you wish to exercise this right please submit our DSR as
                                        available <a href={dsrLink} target="_blank">here</a>.
                                    </td>
                                </tr>
                                <tr>
                                    <td><p>RIGHT TO OPT OUT UNDER THE EU, AND SPECIFICALLY IN THE US THE RIGHT TO OPT
                                        OUT
                                        FROM:</p>
                                        <p>(I) SELLING PERSONAL DATA;</p>
                                        <p>(II) RIGHT TO OPT OUT FROM TARGETED ADVERTISING; AND</p>
                                        <p>(III) RIGHT TO OPT OUT FROM PROFILING AND AUTOMATED DECISION MAKING</p>
                                    </td>
                                    <td><p>You have the right to opt-out from direct marketing, if applicable, by
                                        unsubscribing
                                        through the email received.</p>
                                        <p>If and to the extent applicable to, you have the right to opt out of the sale
                                            of your
                                            Personal Data, or Personal Information, for the purposes of targeted
                                            advertising,
                                            sale to a third party for monetary gain, or for profiling in furtherance of
                                            decisions that produce legal or similarly significant effects concerning you
                                            or any
                                            other consumer.</p>
                                        <p>You may authorize another person acting on your behalf to opt out (including
                                            by technical
                                            tools and opt out signals).</p>
                                        <p>We do "sell" and "share" your Personal Information for analytic and marketing
                                            purposes by using cookies and SDKs. You have the right to opt-out from such
                                            "selling" by clicking the "<span className="text-italic">do not sell or share my personal information</span>"
                                            button
                                            available within our the Partners' apps, or software, through <span
                                                className="text-underline">device settings</span>
                                            (opt-out from tracking AAID, ADID, please see the following for information
                                            applicable to all devices: <a
                                                href="https://thenai.org/opt-out/mobile-opt-out/"
                                                target="_blank">https://thenai.org/opt-out/mobile-opt-out/</a>),
                                            or by using
                                            Self-Regulatory Program for Online Behavioral Advertising such as:
                                            <ul>
                                                <li>Digital Advertising Alliance's ("<span
                                                    className="text-bold">DAA</span>"): <a
                                                    href="https://www.aboutads.info/choices"
                                                    target="_blank">https://www.aboutads.info/choices</a></li>
                                                <li>Network Advertising Initiative ("<span
                                                    className="text-bold">NAI</span>"):
                                                    <a href="https://www.networkadvertising.org/choices"
                                                       target="_blank">https://www.networkadvertising.org/choices</a>.
                                                </li>
                                            </ul>
                                        </p>
                                        <p>Last, you are able to install privacy-controls in the browser's settings to
                                            automatically signal the opt-out preference to all websites you visit (like
                                            the
                                            "<a href="https://globalprivacycontrol.org/" target="_blank">Global Privacy
                                                Control</a>"). We honor the Global Privacy Control, where applicable,
                                            subject to your jurisdiction, as a valid request to opt-out of the sharing
                                            of
                                            information linked to your browser.</p>
                                        <p>You can opt out from sharing or selling data with Search Partners here:</p>
                                        <p><a
                                            href="https://about.ads.microsoft.com/en-us/resources/policies/personalized-ads"
                                            target="_blank">
                                            https://about.ads.microsoft.com/en-us/resources/policies/personalized-ads</a>
                                        </p>
                                        <p><a href="https://legal.yahoo.com/xw/en/yahoo/privacy/optout/index.html"
                                              target="_blank">https://legal.yahoo.com/xw/en/yahoo/privacy/optout/index.html</a>
                                        </p>
                                        <p>In any event, please keep in mind, opt-out tools are limited to the browser
                                            or device you use
                                            because
                                            they work off your browser ID and device ID. Your browser may save some
                                            information in its
                                            cookies
                                            and cache to maintain your privacy preferences. Clearing these may remove
                                            opt-out
                                            preferences,
                                            requiring you to opt-out again. You can read more about targeted adverting
                                            and take
                                            additional steps
                                            to control targeted advertising from many ad networks and exchanges by
                                            visiting the Digital
                                            Advertising Alliance (<a href="http://www.optout.aboutads.info/"
                                                                     target="_blank">www.optout.aboutads.info</a>).
                                            As online environments continue to evolve,
                                            additional opt-out mechanisms or privacy settings may become available to
                                            you. We encourage
                                            you to
                                            review the information on opt-outs and settings that browser owners, device
                                            manufacturers,
                                            technology companies, and industry associations make available to you.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>RIGHT TO APPEAL OR COMPLAINT</td>
                                    <td>If we decline to take action on your request, we shall so inform you without
                                        undue delay as
                                        required under applicable laws. The notification will include a justification
                                        for declining to
                                        take action and instructions on how you may appeal, if applicable. Under the EU
                                        you have the
                                        right to lodge a complaint with the supervisor authority or the Information
                                        Commissioner in the
                                        UK.
                                    </td>
                                </tr>
                                <tr>
                                    <td>NON-DISCRIMINATION</td>
                                    <td>Such discrimination may include denying a good or service, providing a different
                                        level or
                                        quality of service, or charging different prices. We do not discriminate our
                                        users.
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </li>

                    <li className="section">
                        <div className="section-header" id="section-13">JURISDICTION-SPECIFIC NOTICES:</div>
                        <div className="section-content">
                            <ol>
                                <li>
                                    <div className="section-header" id="colorado">ADDITIONAL INFORMATION FOR COLORADO
                                        RESIDENTS
                                    </div>
                                    <div className="section-content">
                                        <p>This section applies to Colorado residents acting only as an individual or
                                            household context.
                                            Pursuant to the Colorado Privacy Act ("<span
                                                className="text-bold">CPA</span>")
                                            please see below the disclosure of the categories of Personal Data that are
                                            collected or
                                            processed, the purposes, how consumers can exercise their rights, and appeal
                                            such
                                            decision,
                                            categories of third-parties the controller shares or sells the personal
                                            data, or sells
                                            the
                                            personal data for advertising and how to opt-out.</p>
                                        <p>Under the CPA, {shortCompanyName} is required to provide a privacy notice
                                            that identifies
                                            the categories of Personal Data that are collected or processed, the
                                            purposes, how consumers
                                            can exercise their rights, and appeal such decision, categories of
                                            third-parties the
                                            controller shares or sells the personal data, or sells the personal data for
                                            advertising and
                                            how to opt-out.</p>
                                        <p>In <a href="#section-4">Section 4</a> to the Privacy Policy, we describe our
                                            collection
                                            and
                                            processing of Personal
                                            Data, the categories of Personal Data that are collected or processed, and
                                            the purposes
                                            for
                                            which Personal Data is processed, stored or used. We will not collect
                                            additional
                                            categories
                                            of Personal Data or use the Personal Data we collected for materially
                                            different,
                                            unrelated,
                                            or incompatible purposes without obtaining your consent. Additionally, <a
                                                href="#section-7">Section
                                                7</a> to this
                                            Privacy Policy details and discloses the categories of third-parties we
                                            share for
                                            business
                                            purposes. <a href="#section-12">Section 12</a> to this Privacy Policy
                                            details and
                                            discloses
                                            your rights and Personal
                                            Data shared or sold for targeted advertising.</p>
                                        <p>Only you, or someone legally authorized to act on your behalf, may make a
                                            request to know or
                                            delete your Personal Data. If the request is submitted by someone other than
                                            the consumer
                                            about whom information is being requested, proof of authorization (such as
                                            power of
                                            attorney or probate documents) will be required.</p>
                                        <p>We will respond to your request within 45 days after receipt of a verifiable
                                            Consumer
                                            Request (no more than twice in a twelve-month period). We reserve the right
                                            to extend
                                            the
                                            response time by an additional 45 days when reasonably necessary and
                                            provided consumer
                                            notification of the extension is made within the first 45 days. If we refuse
                                            to take
                                            action
                                            on a request, you <span
                                                className="text-bold">may appeal our decision</span> within a
                                            reasonable
                                            period time by contacting us
                                            at <a href={`mailto:${privacyPolicyEmail}`}>{privacyPolicyEmail}</a> and
                                            specifying you
                                            wish to
                                            appeal. Within 60 days of our receipt of
                                            your appeal, we will inform you in writing of any action taken or not taken
                                            in response
                                            to
                                            the appeal, including a written explanation of the reasons for the
                                            decisions. If the
                                            appeal
                                            is denied, you may submit a complaint as follows: Colorado AG at <a href="https://coag.gov/file-complaint/"
                                               target="_blank">https://coag.gov/file-complaint/</a>
                                        </p>
                                        <p>Any disclosures we provide will only cover the 12-months period preceding our
                                            receipt of
                                            your request. The response we provide will also explain the reasons we
                                            cannot comply
                                            with a request, if applicable.</p>
                                        <p>If you have an account with us, we may deliver our written response to that
                                            account or
                                            via
                                            email at our sole discretion. If you do not have an account with us, we will
                                            deliver our
                                            written response by mail or electronically, at your option. You do not need
                                            to create an
                                            account for submitting a request.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="section-header" id="connecticut">ADDITIONAL INFORMATION FOR
                                        CONNECTICUT RESIDENTS
                                    </div>
                                    <div className="section-content">
                                        <p>The below disclosures apply to Connecticut residents acting solely in an
                                            individual or household context
                                            pursuant with the Connecticut Data Privacy Act, Public Act. No. 22-14
                                            (the <span
                                                className="text-bold">"CDPA"</span>).</p>
                                        <p>Under CDPA, {shortCompanyName} is required to provide you with a clear and
                                            accessible privacy
                                            notice that includes: categories of Personal Data processed, purpose of
                                            processing, instructions
                                            for exercising consumer rights and appealing decisions, categories of
                                            Personal Data shared
                                            with third parties, categories of third parties with whom data is shared,
                                            and any sale of
                                            data or targeted advertising.</p>
                                        <p>In <a href="#section-4">Section 4</a> to the Privacy Policy, we describe our
                                            collection
                                            and
                                            processing of Personal Data, the categories of Personal Data that are
                                            collected or
                                            processed, and the purposes for which Personal Data is processed, stored or
                                            used. We
                                            will
                                            not collect additional categories of Personal Data or use the Personal Data
                                            we collected
                                            for
                                            materially different, unrelated, or incompatible purposes without obtaining
                                            your
                                            consent.
                                            Additionally, <a href="#section-7">Section 7</a> to this Privacy Policy details and
                                            discloses the
                                            categories of third-parties we share for business purposes. <a href="#section-12">Section 12</a> to this Privacy Policy details and
                                            discloses your
                                            rights and Personal Data shared or sold for targeted advertising. Note,
                                            under CDPA
                                            consent can be withdraw within 15-days of notice at any time.</p>
                                        <p>If we decline to take action on your request, we shall so inform you without
                                            undue
                                            delay,
                                            within 45 days of receipt of your request. The notification will include a
                                            justification
                                            for
                                            declining to take action and instructions on how you may appeal. Within 60
                                            days of our
                                            receipt of your appeal, we will inform you in writing of any action taken or
                                            not taken
                                            in
                                            response to the appeal, including a written explanation of the reasons for
                                            the
                                            decisions. If
                                            the appeal is denied, you may submit a complaint to the Connecticut Attorney
                                            General at
                                            link: <a href="https://www.dir.ct.gov/ag/complaint/">https://www.dir.ct.gov/ag/complaint/</a> or (860) 808-5318.</p>
                                        <p>We shall provide information in response to your request free of charge, up
                                            to twice
                                            annually, unless requests are manifestly unfounded, excessive or repetitive.
                                            If we are
                                            unable to authenticate your request using commercially reasonable efforts,
                                            we may
                                            request
                                            additional information reasonably necessary to authenticate you and your
                                            request. If we
                                            cannot authenticate you and your request, we will not be able to grant your
                                            request.</p>
                                    </div>
                                </li>

                                <li>
                                    <div className="section-header" id="utah">ADDITIONAL INFORMATION FOR UTAH
                                        RESIDENTS
                                    </div>
                                    <div className="section-content">
                                        <p>Under the Utah Consumer Privacy Act ("<span className="text-bold">UCPA</span>")
                                            if you are a
                                            resident of Utah, solely in an individual or household context, your rights
                                            with respect to your
                                            Personal Data are described below. </p>
                                        <p>The UCPA requires {shortCompanyName} to disclose the categories of Personal
                                            Data processed, purpose of
                                            processing, how you can exercise your rights, including your opt-out rights
                                            from the sale of Personal
                                            Data or processing for targeted advertising, the categories of Personal Data
                                            shared with third parties
                                            and with whom, and if {shortCompanyName} sells Personal Data to third
                                            parties or processes Personal
                                            Data for targeted advertising. Note, under the UCPA,
                                            {shortCompanyName} does not "sell" your Personal
                                            Data.</p>
                                        <p>In <a href="#section-4">Section 4</a> to the Privacy Policy, we describe our
                                            collection
                                            and processing of Personal Data, the categories of Personal Data that are
                                            collected or
                                            processed, and the purposes for which Personal Data is processed, stored or
                                            used. We
                                            will not collect additional categories of Personal Data or use the Personal
                                            Data we
                                            collected for materially different, unrelated, or incompatible purposes
                                            without
                                            obtaining your consent. Additionally,
                                            <a href="#section-7">Section 7</a> to this Privacy Policy details and
                                            discloses the
                                            categories of third-parties we share for business purposes.
                                            <a href="#section-12">Section 12</a> to this Privacy Policy details and
                                            discloses your
                                            rights and Personal Data shared or sold for targeted advertising. Note,
                                            under UCPA, our
                                            sharing practices with third-party analytic and advertising tools are not
                                            considered a
                                            "sale".</p>
                                        <p>We will respond to your request within 45 days after receipt of your request
                                            (no more than twice in a
                                            twelve-month period). We reserve the right to extend the response time by an
                                            additional 45 days when
                                            reasonably necessary and provided consumer notification of the extension is
                                            made within the first 45
                                            days. If we refuse to take action on a request, we will provide with the
                                            reasoning for our refusal.</p>
                                        <p>Any disclosures we provide will only cover the 12-months period preceding our
                                            receipt of your request.
                                            The response we provide will also explain the reasons we cannot comply with
                                            a request, if
                                            applicable.</p>
                                        <p>We shall provide information in response to your request free of charge, up
                                            to twice annually, unless
                                            requests are manifestly unfounded, excessive or repetitive. If we are unable
                                            to authenticate your
                                            request using commercially reasonable efforts, we may request additional
                                            information reasonably
                                            necessary to authenticate you and your request. If we cannot authenticate
                                            you and your request, we will
                                            not be able to grant your request.</p>
                                    </div>
                                </li>

                                <li>
                                    <div className="section-header" id="virginia">ADDITIONAL INFORMATION FOR VIRGINIA
                                        RESIDENTS
                                    </div>
                                    <div className="section-content">
                                        <p>Under the Virginia Consumer Data Protection Act, as amended ("<span
                                            className="text-bold">VCDPA</span>")
                                            if you are a resident
                                            of Virginia acting in an individual or household context (and <span
                                                className="text-bold">not</span>
                                            in an employment or
                                            commercial context), you have the following rights with respect to your
                                            Personal
                                            Data.</p>
                                        <p>The VCDPA requires {shortCompanyName} to disclose the categories of Personal
                                            Data processed,
                                            purpose
                                            of processing, how you can exercise your rights, including how a you may
                                            appeal our
                                            decision
                                            with regard to the consumer request, the categories of Personal Data shared
                                            with third
                                            parties and with whom, and if {shortCompanyName} sells Personal Data to
                                            third parties or processes
                                            Personal Data for targeted advertising.</p>
                                        <p>In <a href="#section-4">Section 4</a> to the Privacy Policy, we describe our
                                            collection
                                            and processing of Personal
                                            Data, the categories of Personal Data that are collected or processed, and
                                            the purposes
                                            for
                                            which Personal Data is processed, stored or used. We will not collect
                                            additional
                                            categories
                                            of Personal Data or use the Personal Data we collected for materially
                                            different,
                                            unrelated,
                                            or incompatible purposes without obtaining your consent. Additionally, <a
                                                href="#section-7">Section 7</a> to this
                                            Privacy Policy details and discloses the categories of third-parties we
                                            share for
                                            business
                                            purposes. <a href="#section-12">Section 12</a> to this Privacy Policy
                                            details and
                                            discloses your rights and Personal
                                            Data shared or sold for targeted advertising.</p>
                                        <p>We shall respond to your request within 45 days of receipt. We reserve the
                                            right to extend
                                            the
                                            response time by an additional 45 days when reasonably necessary and
                                            provided consumer
                                            notification of the extension is made within the first 45 days. If we refuse
                                            to take
                                            action
                                            on a request, you may appeal our decision within a
                                            reasonable period time by contacting us
                                            at <a href={`mailto:${privacyPolicyEmail}`}>{privacyPolicyEmail}</a> and
                                            specifying you wish to
                                            appeal. Within 60 days of our receipt of
                                            your appeal, we will inform you in writing of any action taken or not taken
                                            in response
                                            to
                                            the appeal, including a written explanation of the reasons for the
                                            decisions. If the
                                            appeal
                                            is denied, you may submit a complaint to the Virginia Attorney General at
                                            <a href="https://www.oag.state.va.us/consumercomplaintform"
                                               target="_blank">https://www.oag.state.va.us/consumercomplaintform</a>.
                                        </p>
                                        <p>Any disclosures we provide will only cover the 12-months period preceding our
                                            receipt of
                                            your request. The response we provide will also explain the reasons we
                                            cannot comply
                                            with a
                                            request, if applicable.</p>
                                        <p>We shall provide information in response to your request free of charge, up
                                            to twice
                                            annually, unless requests are manifestly unfounded, excessive or repetitive.
                                            If we are
                                            unable to authenticate your request using commercially reasonable efforts,
                                            we may
                                            request
                                            additional information reasonably necessary to authenticate you and your
                                            request. If we
                                            cannot authenticate you and your request we will not be able to grant your
                                            request.</p>
                                        <p>If you have an account with us, we may deliver our written response to that
                                            account or
                                            via
                                            email at our sole discretion. If you do not have an account with us, we will
                                            deliver our
                                            written response by mail or electronically, at your option. You do not need
                                            to create an
                                            account for submitting a request.</p>
                                    </div>
                                </li>
                            </ol>
                        </div>
                    </li>
                </ol>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
