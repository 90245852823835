import React, { useRef, useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import { getRandomNumber } from "../../utils";

import Popup from "./components/Popup";
import Footer from "../Common/Footer";
import Search from "./components/Search";
import Shortcut from "./components/Shortcut";
import EmptyShortcut from "./components/EmptyShortcut";
import SmileboxShortcut from "./components/SmileboxShortcut";

import { useAppState } from "../../hooks";
import { sendMixpanelEvent } from "../../utils";

import { initialShortcuts } from "./constants";

import "./Extension.scss";

function App() {
  const appState = useAppState();
  const [shortcuts, setShortcuts] = React.useState([
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ]);

  const searchInputRef = useRef(null);
  const [tab, setTab] = useState("shortcuts");
  const [showPopup, setShowPopup] = useState(false);
  const [backgroundNumber, setBackgroundNumber] = useState(0);
  const [addShortcutIndex, setAddShortcutIndex] = useState(0);

  const LS_SHORTCUTS_NAME = "smilebox-shortcuts";

  const saveToLS = (value) => {
    localStorage.setItem(LS_SHORTCUTS_NAME, JSON.stringify(value));
  };

  const setupLSShortcuts = () => {
    const lsShortcuts = localStorage.getItem(LS_SHORTCUTS_NAME);

    const sites = [...initialShortcuts, null];

    if (!lsShortcuts) {
      setShortcuts(sites);
      saveToLS(sites);
    } else {
      setShortcuts(JSON.parse(lsShortcuts));
    }
  };

  const chooseAndSetBackground = () => {
    setBackgroundNumber(getRandomNumber(1, 6));
  };

  useEffect(() => {
    sendMixpanelEvent("Extension");
    chooseAndSetBackground();
    setupLSShortcuts();

    if (searchInputRef && searchInputRef.current) {
      setTimeout(() => {
        searchInputRef.current.focus();
      }, 200);
    }
  }, []);

  const onAddShortcutClick = (index) => {
    setShowPopup(true);
    setAddShortcutIndex(index);
  };

  const closePopup = () => {
    setShowPopup(false);
    setAddShortcutIndex(0);
  };

  const onSaveShortcutClick = (title, url) => {
    const shortcutsCopy = [...shortcuts];

    shortcutsCopy[addShortcutIndex] = {
      title,
      url,
    };

    setShortcuts(shortcutsCopy);
    closePopup();
    saveToLS(shortcutsCopy);
  };

  const onRemoveShortcutClick = (index) => {
    const shortcutsCopy = [...shortcuts];

    shortcutsCopy[index] = null;

    setShortcuts(shortcutsCopy);
    saveToLS(shortcutsCopy);
  };

  const setShortcutsTab = () => {
    setTab("shortcuts");
  };

  const setSmileboxShortcutsTab = () => {
    setTab("smilebox-shortcuts");
  };

  const isShortcutsTabActive = tab === "shortcuts";

  return (
    <div>
      <main className={`app app-bg-${backgroundNumber}`}>
        {showPopup ? (
          <Popup
            closePopup={closePopup}
            onSaveShortcutClick={onSaveShortcutClick}
          />
        ) : null}

        <div className="content">
          <a href="https://www.smilebox.com" className="logo" />
        </div>

        <Search ref={searchInputRef} />

        <div className="shortcut-tabs">
          <div
            id="shortcuts-tab"
            className={`shortcut-tab ${
              isShortcutsTabActive ? "shortcut-tab-active" : ""
            }`}
            onClick={setShortcutsTab}
          >
            My shortcuts
          </div>

          <div className="shortcut-tab-divider"></div>

          <div
            id="shortcuts-smilebox-tab"
            className={`shortcut-tab ${
              !isShortcutsTabActive ? "shortcut-tab-active" : ""
            }`}
            onClick={setSmileboxShortcutsTab}
          >
            Smilebox Shortcuts
          </div>
        </div>

        <div className="shortcuts">
          {isShortcutsTabActive
            ? shortcuts.map((shortcut, index) => {
                const id = uuidv4();

                return shortcut ? (
                  <Shortcut
                    key={id}
                    shortcut={shortcut}
                    shortcutIndex={index}
                    onRemoveClick={onRemoveShortcutClick}
                  />
                ) : (
                  <EmptyShortcut
                    key={id}
                    shortcutIndex={index}
                    onAddShortcutClick={onAddShortcutClick}
                  />
                );
              })
            : appState.smileboxShortcuts.map((shortcut) => {
                const id = uuidv4();

                return <SmileboxShortcut key={id} shortcut={shortcut} />;
              })}
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default App;
