import { forwardRef, useRef, useEffect, useState } from "react";

import { useAppState } from "../../../hooks";

const SEARCH_SOURCE_ID = 69;
const SEARCH_URL = "https://search.smilebox.co/results.aspx";

const AUTOCOMPLETE_URL = `https://suggest.smilebox.co/suggestionFeed/Suggestion`;

const Search = forwardRef((_, upperRef) => {
  const ref = useRef();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchItems, setSearchItems] = useState([]);
  const [selectedSearchResult, setSelectedSearchResult] = useState(-1);

  const { publisherId } = useAppState();

  const hasSearchItems = searchItems.length;

  const clearSearchItems = () => {
    setSearchItems([]);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (hasSearchItems && ref.current && !ref.current.contains(e.target)) {
        clearSearchItems();
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [hasSearchItems]);

  const clearSelectedItemOnMouseEnter = () => {
    if (selectedSearchResult !== -1) {
      setSelectedSearchResult(-1);
    }
  };

  const onSearchInputChange = (e) => {
    const value = e.target.value;

    setSearchTerm(value);

    if(!value) {
      clearSearchItems();
      return;
    }

    const urlWithParams = `${AUTOCOMPLETE_URL}?format=json&q=${value}&gd=${publisherId}`;

    fetch(urlWithParams)
      .then((r) => r.json())
      .then((data) => {
        if (data && data[1]) {
          data[1].splice(10);

          setSearchItems(data[1]);
        }
      });
  };

  const searchBySearchTerm = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (searchTerm) {
      const searchTermEncoded = encodeURI(searchTerm);
      const urlWithParams = `${SEARCH_URL}?gd=${publisherId}&searchsource=${SEARCH_SOURCE_ID}&q=${searchTermEncoded}`;

      window.location.href = urlWithParams;
    }
  };

  const searchByAutocompleteItem = (searchItem) => {
    const searchItemEncoded = encodeURI(searchItem);
    const urlWithParams = `${SEARCH_URL}?gd=${publisherId}&searchsource=${SEARCH_SOURCE_ID}&q=${searchItemEncoded}`;

    console.log(urlWithParams);

    window.location.href = urlWithParams;
  };

  return (
    <form className="search-box">
      <div
        ref={ref}
        onMouseEnter={clearSelectedItemOnMouseEnter}
        className={`${searchItems.length ? "search-box-input-container" : ""}`}
      >
        <input
          ref={upperRef}
          value={searchTerm}
          placeholder="Search"
          className={`search-input ${
            searchItems.length ? "search-input-with-results" : ""
          }`}
          onChange={onSearchInputChange}
          onKeyDown={(e) => {
            if (e.code === "ArrowDown") {
              if (selectedSearchResult === 9) {
                return;
              } else {
                setSelectedSearchResult((res) => res + 1);
              }
            } else if (e.code === "ArrowUp") {
              if (selectedSearchResult === -1) {
                return;
              } else {
                setSelectedSearchResult((res) => res - 1);
              }
            } else if (e.code === "Enter") {
              e.preventDefault();
              e.stopPropagation();

              if (selectedSearchResult !== -1) {
                const searchTerm = searchItems[selectedSearchResult];

                searchByAutocompleteItem(searchTerm);
              } else {
                searchBySearchTerm();
              }
            }
          }}
        />

        {searchItems.length ? (
          <ul className="search-box-results">
            {searchItems.map((item, index) => {
              return (
                <li
                  key={item}
                  className={`search-box-result ${
                    selectedSearchResult === index
                      ? "search-box-result-selected"
                      : ""
                  }`}
                  onClick={() => searchByAutocompleteItem(item)}
                >
                  {item}
                </li>
              );
            })}
          </ul>
        ) : null}
      </div>

      <button
        type="button"
        className="search-btn"
        onClick={searchBySearchTerm}
      />
    </form>
  );
});

export default Search;
