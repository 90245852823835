import "./Footer.scss";

import { Link } from "react-router-dom";

const Footer = () => (
  <footer>
    <div className="footer-left">© Smilebox. All rights reserved.</div>
    <div className="footer-right">
      <ul>
        <li>
          <Link target="blank" to="about-us">
            About us
          </Link>
        </li>

        <li>
          <Link target="blank" to="terms-of-use">
            Terms of use
          </Link>
        </li>

        <li>
          <Link target="blank" to="privacy-policy">
            Privacy policy
          </Link>
        </li>

        <li>
          <Link target="blank" to="contact">
            Contact
          </Link>
        </li>
      </ul>
    </div>
  </footer>
);

export default Footer;
