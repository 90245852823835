import { useEffect } from "react";

import PageTitle from "../Common/PageTitle";

import { sendMixpanelEvent } from "../../utils";

import logoSrc from "../../images/logo.png";
import extensionImgSrc from "../../images/thankyou-extension.jpg";

import "./Thankyou.scss";

const Thankyou = () => {
  useEffect(() => {
    sendMixpanelEvent("Thank you");
  }, []);

  return (
    <div className="thankyou">
      <PageTitle title="Thank you!" />

      <div className="content">
        <a href="index.html">
          <img className="logo" src={logoSrc} alt="logo" />
        </a>

        <h1>Thank You!</h1>
        <h2>Smilebox extension is now successfully installed</h2>

        <img
          className="screenshot"
          src={extensionImgSrc}
          alt="extension-screenshot"
        />
      </div>
    </div>
  );
};

export default Thankyou;
