import React from "react";
const Shortcut = ({ shortcut, onRemoveClick, shortcutIndex }) => {
  const onRemoveBtnClick = () => {
    onRemoveClick(shortcutIndex);
  };

  const shortcutFaviconLetter = shortcut?.title[0]?.toUpperCase() || "";

  return (
    <div className="shortcut-container">
      <a className="shortcut" href={shortcut?.url}>
        {shortcut.img ? (
          <div
            className={`shortcut-favicon shortcut-favicon-${shortcut.img}`}
          />
        ) : (
          <div className="shortcut-favicon-text">{shortcutFaviconLetter}</div>
        )}
      </a>

      <button className="shortcut-remove" onClick={onRemoveBtnClick}></button>
      <span className="shortcut-title">{shortcut?.title}</span>
    </div>
  );
};

export default Shortcut;
