import { useState } from "react";

const Popup = ({ onSaveShortcutClick, closePopup }) => {
  const [addShortcutTitle, setAddShortcutTitle] = useState("");
  const [addShortcutUrl, setAddShortcutUrl] = useState("");

  const onNameChange = (e) => {
    setAddShortcutTitle(e.target.value);
  };

  const onUrlChange = (e) => {
    setAddShortcutUrl(e.target.value);
  };

  const onSaveBtnClick = () => {
    let preparedUrl = addShortcutUrl;
    const isUrlContainsProtocol =
      addShortcutUrl.includes("http://") || addShortcutUrl.includes("https://");

    if (!isUrlContainsProtocol) {
      preparedUrl = "https://" + preparedUrl;
    }

    onSaveShortcutClick(addShortcutTitle, preparedUrl);
  };

  const isValidURL = (str) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    return !!pattern.test(str);
  };

  const isUrlValid = isValidURL(addShortcutUrl);

  const onFormSubmit = (e) => {
    e.preventDefault();
    console.log("onFormSubmit()");

    if (isUrlValid) {
      onSaveBtnClick();
    }
  };

  return (
    <div className="popup-container">
      <div className="popup">
        <div className="popup-heading">Add Shortcut</div>

        <form onSubmit={onFormSubmit}>
          <input
            type="text"
            placeholder="Name"
            className="popup-input"
            onChange={onNameChange}
            value={addShortcutTitle}
          />

          <input
            type="text"
            placeholder="URL"
            onChange={onUrlChange}
            value={addShortcutUrl}
            className="popup-input"
          />

          <div className="popup-btns">
            <button
              type="button"
              onClick={closePopup}
              className="popup-btn popup-btn-cancel"
            >
              Cancel
            </button>

            <button
              disabled={!isUrlValid || !addShortcutTitle}
              type="submit"
              onClick={onSaveBtnClick}
              className={`popup-btn popup-btn-save ${
                !isUrlValid || !addShortcutTitle ? "popup-btn-disabled" : ""
              }`}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Popup;
