import mixpanel from "mixpanel-browser";

export const getRandomNumber = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min);

export const generateSmileboxShortcuts = (sbid) => {
  return [
    {
      title: "Birthdays",
      img: "birthdays.jpg",
      url: `https://plus.smilebox.com/?sbid=${sbid}&c=2&s=2`,
      className: "birthdays",
    },
    {
      title: "Weddings",
      img: "wedding.jpg",
      url: `https://plus.smilebox.com/?sbid=${sbid}&c=2&s=3`,
      className: "wedding",
    },
    {
      title: "Memorials",
      img: "memorials.jpg",
      url: `https://plus.smilebox.com/?sbid=${sbid}&c=9&s=40`,
      className: "memorials",
    },
    {
      title: "Greetings",
      img: "cards.jpg",
      url: `https://plus.smilebox.com/?sbid=${sbid}&f=2`,
      className: "cards",
    },
    {
      title: "Business",
      img: "business.jpg",
      url: `https://plus.smilebox.com/?sbid=${sbid}&c=13 `,
      className: "business",
    },
    {
      title: "Thank you",
      img: "thank-you.jpg",
      url: `https://plus.smilebox.com/?sbid=${sbid}&c=2&s=63`,
      className: "thank-you",
    },
    {
      title: "Seasonal",
      img: "seasonal.jpg",
      url: `https://plus.smilebox.com/?sbid=${sbid}&c=1`,
      className: "seasonal",
    },
    {
      title: "All",
      img: "all.jpg",
      url: `https://plus.smilebox.com/?sbid=${sbid}`,
      className: "all",
    },
  ];
};

export const sendMixpanelEvent = (pageName) => {
  mixpanel.track(pageName);
};

export const getUrlParamValue = (paramName) => {
  const url_string = window.location.href;
  const url = new URL(url_string);
  const value = url.searchParams.get(paramName);

  return value;
};
