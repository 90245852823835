import { useEffect } from "react";

import { useState } from "react";
import { Link } from "react-router-dom";

import { sendMixpanelEvent } from "../../utils";

import PageTitle from "../Common/PageTitle";

import logoSrc from "../../images/logo.png";

import "./Uninstall.scss";

const Uninstall = () => {
  const [reasons, setReasons] = useState({
    slow: false,
    dontNeed: false,
    modify: false,
  });

  useEffect(() => {
    sendMixpanelEvent("Uninstall");
  }, []);

  const [isFeedbackWasSent, setIsFeedbackWasSent] = useState(false);

  const chooseReason = (key) => {
    const reasonsCopy = { ...reasons };

    reasonsCopy[key] = !reasonsCopy[key];

    setReasons(reasonsCopy);
  };

  const chooseSlow = () => {
    chooseReason("slow");
  };

  const chooseDontNeed = () => {
    chooseReason("dontNeed");
  };

  const chooseModify = () => {
    chooseReason("modify");
  };

  const sendFeedback = () => {
    setIsFeedbackWasSent(true);
  };

  return (
    <div className="uninstall">
      <PageTitle title="Uninstall" />

      <div className="content">
        <div className="content-inner">
          <div className="content-header">
            <Link to="/">
              <img alt="logo" className="logo" src={logoSrc} />
            </Link>

            <h1>Thank you for trying Smilebox extension</h1>
            <h2>
              Your opinion matters. We greatly appreciate any feedback you can
              provide to help us continue and improve the extension.
            </h2>
          </div>

          <div className="content-reason-form">
            {isFeedbackWasSent ? (
              <h2>Thank you for your feedback!</h2>
            ) : (
              <>
                <p>
                  Which of the following most accurately describes why you're
                  uninstalling the extension?
                </p>

                <div className="reasons">
                  <div className="reason" onClick={chooseSlow}>
                    <input
                      type="checkbox"
                      onChange={chooseSlow}
                      checked={reasons.slow}
                    />
                    <span>I think it's slowing down my computer.</span>
                  </div>

                  <div className="reason" onClick={chooseDontNeed}>
                    <input
                      type="checkbox"
                      onChange={chooseDontNeed}
                      checked={reasons.dontNeed}
                    />
                    <span>I don’t need it.</span>
                  </div>

                  <div className="reason" onClick={chooseModify}>
                    <input
                      type="checkbox"
                      onChange={chooseModify}
                      checked={reasons.modify}
                    />
                    <span>I didn't know it could modify my new tab page.</span>
                  </div>
                </div>

                <button className="send" onClick={sendFeedback}>
                  Send
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Uninstall;
