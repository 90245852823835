import React from "react";

const EmptyShortcut = ({ shortcutIndex, onAddShortcutClick }) => {
  const onAddShortcutClickWrapper = () => {
    onAddShortcutClick(shortcutIndex);
  };

  return (
    <div className="shortcut-container" data-index="0">
      {/* eslint-disable-next-line */}
      <a className="shortcut" onClick={onAddShortcutClickWrapper}>
        <div className="shortcut-add" />
      </a>

      <span className="shortcut-title">{}</span>
    </div>
  );
};

export default EmptyShortcut;
