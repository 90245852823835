import { useEffect } from "react";
import { sendMixpanelEvent } from "../../utils";

import PageTitle from "../Common/PageTitle";

const ContactUs = () => {
  useEffect(() => {
    sendMixpanelEvent("Contact us");
  }, []);

  return (
    <div className="content">
      <PageTitle title="Contact" />

      <h1>Contact Us</h1>

      <div className="text-center">
        <h2>We'd love to get your feedback</h2>
      </div>

      <p>
        <strong>How to Contact the Company</strong>
      </p>

      <p>
        If you have any questions or concerns with respect to the Smilebox Tab
        Browser Extension, you may contact us by email{" "}
        <a href="mailto: extsupport@smilebox.com">extsupport@smilebox.com</a> or
        at the following address:
      </p>

      <p>
        Smilebox, Inc. C/O Perion Network Ltd
        <br />
        General Counsel 26 HaRokmim Street
        <br />
        Azrieli Center 1 Holon 5885849
        <br />
        Israel.
      </p>
    </div>
  );
};

export default ContactUs;
