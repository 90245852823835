export const initialShortcuts = [
  {
    url: "https://facebook.com",
    title: "Facebook",
    img: "facebook",
  },
  {
    url: "https://youtube.com",
    title: "Youtube",
    img: "youtube",
  },
  {
    url: "https://instagram.com",
    title: "Instagram",
    img: "instagram",
  },
  {
    url: "https://google.com",
    title: "Google",
    img: "google",
  },
  {
    url: "https://amazon.com",
    title: "Amazon",
    img: "amazon",
  },

  {
    url: "https://twitter.com",
    title: "Twitter",
    img: "twitter",
  },

  {
    url: "https://yahoo.com",
    title: "Yahoo",
    img: "yahoo",
  },
];
