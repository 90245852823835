import { useEffect } from "react";
import mixpanel from "mixpanel-browser";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import logoSrc from "../../images/logo.png";
import benefit1Src from "../../images/benefit_1.png";
import benefit2Src from "../../images/benefit_2.png";
import benefit3Src from "../../images/benefit_3.png";

import Footer from "../Common/Footer";
import { useAppState } from "../../hooks";
import { sendMixpanelEvent } from "../../utils";

import "./Landing.scss";

const Landing = () => {
  const appState = useAppState();

  useEffect(() => {
    sendMixpanelEvent("Landing");
  }, []);

  const onInstallBtnClick = async () => {
    await mixpanel.track("Get extension btn click");

    window.location.href = appState.extensionInstallLink;
  };

  return (
    <div className="landing">
      <Helmet>
        <title>Smilebox Tab extension</title>
      </Helmet>

      <div className="banner">
        <header>
          <Link to="/">
            <img alt="logo" className="header-logo" src={logoSrc} />
          </Link>
        </header>

        <div className="content">
          <div className="title">
            <span className="title__bold">Get quick access</span>
            <span className="title__normal">
              to beautiful ecards with this new tab Smilebox extension
            </span>
          </div>

          <div className="description">
            {appState.browserName}™ Extension with New Tab Search
          </div>

          <button onClick={onInstallBtnClick} className="add-ext-button">
            Add to {appState.browserName}
          </button>

          <div className="disclaimer">
            By clicking 'Add to {appState.browserName}', you accept and agree to
            install the Smilebox {appState.browserName} extension, set the{" "}
            {appState.browserName}™ New Tab to Smilebox Tab, and to the{" "}
            <Link to="/terms-of-use">Terms of Use</Link> and{" "}
            <Link to="/privacy-policy">Privacy Policy</Link>.{" "}
            {appState.browserName === "Chrome"
              ? "Please note that the extension will change your New Tab provider to Bing."
              : ""}
          </div>
        </div>
      </div>

      <div className="benefits">
        <div className="benefit">
          <img alt="benefit" src={benefit1Src} />
          <span>Access 3500+ original designs for every occasion</span>
        </div>

        <div className="benefit">
          <img alt="benefit" src={benefit2Src} />
          <span>Enjoy quick access to your favourite sites</span>
        </div>

        <div className="benefit">
          <img alt="benefit" src={benefit3Src} />
          <span>Use the New tab search, included with the extension</span>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Landing;
