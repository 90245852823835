import PageTitle from "../Common/PageTitle";
import { useAppState } from "../../hooks";

import { sendMixpanelEvent } from "../../utils";
import { useEffect } from "react";

const AboutUs = () => {
  const appState = useAppState();

  useEffect(() => {
    sendMixpanelEvent("About us");
  }, []);

  return (
    <div className="content">
      <PageTitle title="About us" />

      <h1>About Us</h1>

      <div class="text-content">
        <p>
          <a href="https://smilebox.com" target="_blank" rel="noreferrer">
            Smilebox
          </a>{" "}
          was founded in 2005 with one goal in mind: to help you connect with
          your family and friends by sharing life's special moments - big and
          small. And today we stay true to our roots. Our mission has evolved
          into providing you with the best ways to tell your life's stories.
          Whether it's a big moment like a wedding, or a small, precious moment
          like getting your first puppy, or an inevitable moment like a
          memorial...
        </p>

        <p>We make the telling worthy of the stories.</p>

        <hr />

        <p>
          You can uninstall the extension at any time. To remove our extension
          from {appState.browserName}:
          <ol>
            <li>
              Click on the vertical dots icon in the upper right-hand corner of
              your {appState.browserName} browser.
            </li>
            <li>Go to "More tools".</li>
            <li>In the left menu that appears click on "Extensions".</li>
            <li>
              From the list of installed Extensions, find the name of the
              extension you wish to uninstall.
            </li>
            <li>
              Click on the "Remove" button to the right of "Details" button (for
              the extension you wish to uninstall).
            </li>
            <li>
              Close your browser completely and reopen it. The extension should
              now be removed from your {appState.browserName} browser.
            </li>
          </ol>
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
