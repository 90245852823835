import { useEffect } from "react";
import {Helmet} from "react-helmet";

const Transfer = () => {
    useEffect(() => {
        // On load redirect to the Smilebox search page with all the parameters
        window.location.href = 'https://search.smilebox.co/' +  window.location.search;
    }, []);

    return (
        <Helmet>
            <meta name="referrer" content="origin" />
        </Helmet>
    );
}

export default Transfer;
