import mixpanel from "mixpanel-browser";

import "./App.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Extension from "./pages/Extension/Extension";
import Landing from "./pages/Landing/Landing";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService/TermsOfService";
import Thankyou from "./pages/Thankyou/Thankyou";
import Uninstall from "./pages/Uninstall/Uninstall";
import ContactUs from "./pages/ContactUs/ContactUs";
import AboutUs from "./pages/AboutUs/AboutUs";
import Transfer from "./pages/Transfer/Transfer";
import LegalWrapper from "./pages/Common/LegalWrapper";
import AppContext from "./pages/Common/AppContext";
import setupAppState from "./setupAppState";

const appState = setupAppState();
const MIXPANEL_TOKEN = "a5185fee4e1659218652c7890b4c9bca";

mixpanel.init(MIXPANEL_TOKEN);

function App() {
  return (
    <div className="App">
      <AppContext.Provider value={appState}>
        <Router>
          <Switch>
            <Route path="/new-tab">
              <Extension />
            </Route>

            <Route path="/privacy-policy">
              <LegalWrapper>
                <PrivacyPolicy />
              </LegalWrapper>
            </Route>

            <Route path="/terms-of-use">
              <LegalWrapper>
                <TermsOfService />
              </LegalWrapper>
            </Route>

            <Route path="/thankyou">
              <Thankyou />
            </Route>

            <Route path="/uninstall">
              <Uninstall />
            </Route>

            <Route path="/about-us">
              <LegalWrapper>
                <AboutUs />
              </LegalWrapper>
            </Route>

            <Route path="/contact">
              <LegalWrapper>
                <ContactUs />
              </LegalWrapper>
            </Route>

            <Route path="/transfer">
              <Transfer />
            </Route>

            <Route path="/">
              <Landing />
            </Route>
          </Switch>
        </Router>
      </AppContext.Provider>
    </div>
  );
}

export default App;
